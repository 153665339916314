<template>
  <div>
    <div class="exams" dir="rtl">
      <h3 class="heading text-center">
        {{ "المواقع" | translate }}({{ filteredLocations.length }})
      </h3>
      <div class="filters bg-dark p-2 row">
        <div class="col-md-3">
          <label>{{ "ابحث عن موقع" | translate }}</label>
          <input @input="search" type="search" class="form-control" />
        </div>
      </div>
      <hr />
      <responsive-table
        title="مواقع"
        :loading="loading"
        :items="filteredLocations"
        :colsNames="['الموقع']"
        :colsTitles="['name']"
        :colsTypes="['readonly']"
        :btns="[{ icon: 'trash', method: remove }]"
        color="#8b633bd6"
      />
      <hr />
    </div>
    <!----------------->
    <div v-if="loading" class="center">
      <loading />
    </div>
    <!----------------->
    <div class="add-exam p-2" dir="rtl">
      <h3 class="heading text-center">{{ "أضافة موقع" | translate }}</h3>
      <div class="bg-dark g-4 row py-3">
        <div class="col-md-6">
          <div class="form-group">
            <label>{{ "موقع جديد" | translate }}</label>
            <input v-model="location" type="text" class="form-control" />
          </div>
        </div>
        <div
          class="col-md-3 d-flex justify-content-center align-items-end align-content-center"
        >
          <button
            :disabled="!location"
            @click="addLocation"
            class="btn btn-primary"
            style="width: 90%"
          >
            {{ "اضف الموقع" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { formatDate } from "../functions";
import axios from "axios";
export default {
  created() {
    axios.get("/getLocations").then((res) => {
      this.locations = res.data.locations;
      this.filteredLocations = res.data.locations;
    });
  },
  data() {
    return {
      locations: [],
      filteredLocations: [],
      loading: false,
      location: null,
    };
  },
  methods: {
    addLocation() {
      this.loading = true;
      axios.post("/addLocation", { name: this.location }).then((res) => {
        if (res.data.err) return alert(res.data.err);
        this.loading = false;
        this.filteredLocations.push(res.data.location);
        this.$store.dispatch("getLocations");
      });
    },
    remove(item) {
      const confirmed = confirm("مسح الموقع نهائيا...");
      if (!confirmed) return;
      this.loading = true;
      axios.delete("/deleteLocation/" + item._id).then((res) => {
        this.loading = false;
        if (res.data.err) return alert(res.data.err);
        this.filteredLocations.splice(
          this.filteredLocations.findIndex((fe) => fe._id === item._id),
          1
        );
      });
    },
    search(event) {
      const val = event.target.value;
      if (val == null) {
        this.filteredLocations = [...this.locations];
        return;
      }
      this.filteredLocations = this.locations.filter((st) => {
        if (st.name.toString().includes(val)) return true;
      });
    },
  },
};
</script>
<style scoped lang="scss">
label {
  color: #fff;
}
.exam {
  div {
    margin: 5px 0;
    p {
      display: inline;
    }
  }
}
.exam {
  padding: 4px 0;
  cursor: pointer;
  &:nth-child(odd) {
    background: rgb(179, 177, 177);
  }
}
.student {
  background: rgba(42, 88, 110, 0.844);
  color: #fff;
  margin: 5px 0;
  padding: 2px;
}
.attended {
  background: rgba(30, 92, 30, 0.762);
}
.indicators {
  margin-top: 8px;
  div {
    display: flex;
    span {
      margin-right: 5px;
      color: #666;
    }
  }
}
.green {
  width: 20px;
  height: 20px;
  background: rgba(30, 92, 30, 0.762);
}

.blue {
  width: 20px;
  height: 20px;
  background: rgba(42, 88, 110, 0.844);
}
</style>
